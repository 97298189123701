import React from 'react';
import { OptionsContainer, OptionText, OptionContainer } from '../styles/ProTabStyles';

import { ReactComponent as SketchesIcon } from '../../../../assets/icons/sketches.svg';
import { ReactComponent as QuotaIcon } from '../../../../assets/icons/quota.svg';
import { ReactComponent as DocumentsIcon } from '../../../../assets/icons/documents.svg';
import styled from 'styled-components';

const StyledSketchesIcon = styled(SketchesIcon)`
    width: 19;
    height: 16;
    fill: var(--red);

    @media (max-width: 768px) {
        width: 30;
        height: 24;
    }
`;

const StyledQuotaIcon = styled(QuotaIcon)`
    width: 19;
    height: 16;
    fill: var(--red);

    @media (max-width: 768px) {
        width: 30;
        height: 24;
    }
`;

const StyledDocumentsIcon = styled(DocumentsIcon)`
    width: 19;
    height: 16;
    fill: var(--red);

    @media (max-width: 768px) {
        width: 30;
        height: 24;
    }
`;

const ProTabFiles = ({ pro }) => {
    return (
        <OptionsContainer>
            <OptionContainer>
                <StyledSketchesIcon />
                <OptionText>Sketches</OptionText>
            </OptionContainer>
            <OptionContainer>
                <StyledQuotaIcon />
                <OptionText>Quota</OptionText>
            </OptionContainer>
            <OptionContainer>
                <StyledDocumentsIcon />
                <OptionText>Documents</OptionText>
            </OptionContainer>
        </OptionsContainer>
    );
};

export default ProTabFiles;
