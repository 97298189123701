import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// import env from '../../config';

import { ReactComponent as Logo } from '../../assets/icons/orange-fireworks.svg';

import { LoginLayout, LoginHeader, StyledInput, LogoContainer, RegularText, ButtonContainer } from '../Login/LoginStyles';
import { PrimaryButton, FlexColumnCentered } from '../../common/components/CommonStyledComponent';
import { authUserLogin, sendSms } from '../../store/reducers/auth/auth-reducer';
import PhoneInput from '../../common/components/PhoneField';

const Login = () => {
    const dispatch = useDispatch();

    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');
    const [error, setError] = useState();
    const [codeSended, setCodeSended] = useState(false);
    const buttonRef = useRef();

    const onSendSms = () => {
        let data = {
            phone: '+' + phone,
        };
        dispatch(sendSms(data)).then((data) => {
            if (data.payload.error) {
                buttonRef.current.blur();
                setError(data.payload.error);
            } else {
                buttonRef.current.blur();
                setCodeSended(true);
            }
        });
    };

    const onLogin = () => {
        let data = {
            grant_type: 'phone_verify',
            phone: '+' + phone,
            code: code,
        };
        onLoginAttempt(data);
    };

    const onLoginAttempt = (data) => {
        dispatch(authUserLogin(data));
    };

    return (
        <LoginLayout>
            {/* <FlexColumnCentered> */}
            <LogoContainer>
                <Logo width={'100%'} height={'100%'} fill='#EC5C57' />
            </LogoContainer>
            <LoginHeader>Log In</LoginHeader>
            <FlexColumnCentered style={{ width: '25%', minWidth: '250px' }}>
                {error ? <RegularText style={{ color: 'var(--red)', margin: '20px 0' }}>{error}</RegularText> : null}
                <PhoneInput
                    placeholder={'Phone'}
                    value={phone}
                    onChange={(value, country, e, formattedValue) => {
                        setPhone(value);
                    }}
                    disabled={codeSended}
                />
                {codeSended ? (
                    <FlexColumnCentered>
                        <RegularText>Code has been sent!</RegularText>
                        <StyledInput placeholder={'Code'} value={code} onChange={(e) => setCode(e.target.value)} />
                        <RegularText
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setCodeSended(false);
                            }}>
                            Send Again
                        </RegularText>
                    </FlexColumnCentered>
                ) : null}
            </FlexColumnCentered>
            {/* </FlexColumnCentered> */}
            <FlexColumnCentered>
                <ButtonContainer marginTop={'50px'}>
                    <PrimaryButton disabled={codeSended ? code.length !== 4 : phone.length < 5} ref={buttonRef} onClick={codeSended ? onLogin : onSendSms}>
                        {codeSended ? 'Login' : 'Send SMS'}
                    </PrimaryButton>
                </ButtonContainer>
                <RegularText style={{ margin: '50px 20%' }}>By signing in you agree with our communications and usage terms.</RegularText>
            </FlexColumnCentered>
        </LoginLayout>
    );
};

export default Login;
