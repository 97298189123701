import styled from 'styled-components';

export const MessagesGrid = styled.div`
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 60px;
    box-sizing: border-box;
    grid-template-areas: "messages chat";

    @media (max-width: 768px) {
        padding: 0px;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: "messages";
        grid-gap: 0px;
        padding: 0px 20px 20px 20px;
        margin-top: 60px;
    }
`;

export const ChatHeader = styled.div`
    font-weight: 600;
    font-size: 43px;
    color: #222222;
    padding-top: 40px;
    padding-left: 40px;
    @media (max-width: 768px) {
        padding: 0px;
        font-size: 24px;
    }
`;

export const Bubble = styled.div`
    display: flex;
    padding: 21px;
    box-sizing: border-box;
    color: ${props => props.selected ? '#106CC1' : '#222222'};
    justify-content: space-between;
    background: ${props => props.selected ? '#F3F9FE' : 'white'};
    border-radius: 63px;
    cursor: pointer;
    margin: 14px 0px;
    align-items: center;

    @media (max-width: 768px) {
        padding: 10px 0px;
        width: 100%;
        justify-content: flex-start;
    }
`;

export const ChatInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    margin-left: auto;
    margin-left: 50px;

    @media (max-width: 768px) {
        margin-left: 20px;
    }
`;

export const ChatDate = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
`;

export const ChatName = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 17px;
`;

export const ChatIcons = styled.div`
    display: flex;
    @media (max-width: 768px) {
        width: 120px;
    }
`;

export const ChatAvatar = styled.img`
    width: 52px;
    height: 52px;
    min-height: 52px;
    border-radius: 100%;
    margin-right: -36px;
`;

export const ChatLayout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    grid-area: chat;

    @media(max-width: 768px){
        position: fixed;
        background: white;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
`;

export const ChatTitle = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #222222;
    margin-right: 20px;
`;

export const ChatTime = styled.div`
    font-size: 18px;
    color: #222222;
    opacity: 0.78;
`;

export const ChatHeaderContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const ChatSeparator = styled.div`
    height: 1px;
    width: 100%;
    opacity: 0.1;
    background: #222222;
    margin: 20px 0px;
    @media(max-width: 768px){
        margin: 10px 0px;
    }
`;

export const AvatarName = styled.div`
    word-break: break-word;
    font-weight: 600;
    font-size: 10px;
    color: #000000;
    margin-top: 10px;
    text-align: center;
`;

export const ChatInput = styled.input`
    width: 100%;
    border: none;
    outline: none;
    font-size: 18px;
    padding: 16px 0px;

    ::placeholder { 
        color: #222222;
        opacity: 0.4;
    }

    @media(max-width: 768px){
        padding: 10px 0px;
    }
`;

export const ChatMessages = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
`;

export const AvatarContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 54px;
    padding-right: 16px;
`;

export const AvatarFlex = styled.div`
    padding: 20px 0px 42px 0px;
    box-sizing: border-box;
    height: fit-content;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
`;

export const ChatMessageContainer = styled.div`
    display: flex;
    align-items: center;
    align-self: ${props => props.isMe ? 'flex-end' : 'flex-start'};
    margin: 10px 0px;
`;

export const ChatBubble = styled.div`
    padding: 20px;
    background: ${props => props.isMe ? '#F3F9FE' : 'white'};
    color: ${props => props.isMe ? '#106CC1' : '#222222'};
    font-weight: ${props => props.isMe ? '400' : '500'};
    font-size: 14px;
    line-height: 18px;
    border-radius: 9px;
`;

export const UnreadCount = styled.div`
    font-weight: 700;
    font-size: 16px;
    color: #EC5C57;
    margin: 0px auto;
`;