import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PageHeader = styled.div`
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: var(--black);

    @media (max-width: 768px) {
        padding: 0px 20px;
    }
`;

export const Text = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    margin-top: 19px;
    color: var(--black);

    @media (max-width: 768px) {
        margin-top: 0px;
        padding: 42px 30px;
    }
`;

export const SimpleText = styled.div`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
    color: var(--dark);
    margin-top: 80px;
    margin-bottom: 20px;
`;

export const WhiteButton = styled.button`
    background: #ffffff;
    box-shadow: 0px 2px 0px rgba(148, 148, 148, 0.25);
    border-radius: 35.5px;
    color: #363636;
    height: 72px;
    width: 200px;
    font-weight: 700;
    font-size: 18px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #fff4f3;
    }

    &:active {
        background: #ec5c57;
    }

    @media (max-width: 768px) {
        height: 64px;
    }
`;

export const googleButtonStyles = {
    color: 'rgba(0,0,0,0.54)',
    width: '275px',
    fontFamily: 'Poppins',
    backgroundColor: 'white',
    fontSize: '20px',
    fontWeight: 'bold',
    borderRadius: '10px',
    overflow: 'hidden',
    marginBottom: '20px',
};

// TODO - remove unwanted styles
/* ------------------------------ */

export const StyledInput = styled.input`
    color: ${(props) => props.color || 'var(--neutral-90p)'};
    font-size: 16px;
    padding: 0px 20px;
    height: 44px;
    margin: 8px auto;
    width: calc(100% - 42px);
    border-style: groove;
    border: 1px solid var(--neutral-30p);
    &:disabled {
        border: 1px solid var(--neutral-20p);
        color: var(--neutral-50p);
    }

    &.readOnly:disabled {
        color: var(--neutral-80p);
    }

    &:focus {
        outline: none;
        color: var(--neutral-90p);
    }

    &::-webkit-input-placeholder {
        /* Edge */
        font-family: InterUI-Regular;
        font-size: 16px;
        line-height: 16px;
        color: var(--neutral-50p);
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-family: InterUI-Regular;
        font-size: 16px;
        line-height: 16px;
        color: var(--neutral-50p);
    }

    &::placeholder {
        font-family: InterUI-Regular;
        font-size: 16px;
        line-height: 16px;
        color: var(--neutral-50p);
    }
`;

export const Label = styled.label`
    display: block;
    font-size: 16px;
    align-self: flex-start;
    font-weight: bold;
`;

export const StyledTextArea = styled.textarea`
    font-family: InterUI-Regular;
    resize: none;
    height: ${(props) => props.height || '76px'};
    width: ${(props) => props.width || '100%'};
    border: 1px solid var(--neutral-30p);
    padding: 0.625rem;
    overflow: auto;
    font-size: 16px;
    border: 1px solid var(--neutral-30p);
    &:focus {
        outline: none;
        color: var(--neutral-90p);
    }
    &:disabled {
        border: 1px solid var(--neutral-20p);
        color: var(--neutral-50p);
    }
    &.readOnly:disabled {
        color: var(--neutral-80p);
    }
`;

export const ExternalLink = styled.a`
    color: ${(props) => (props.color ? props.color : 'var(--blue-shade-light)')};
    text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};

    &:hover {
        cursor: pointer;
    }
`;

export const StyledText = styled.div`
    color: ${(props) => (props.color ? props.color : 'var(--blue-shade-light)')};
    text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
    font-size: 16px;
    font-family: InterUI-Regular;
    padding-left: 16px;

    &:hover {
        cursor: pointer;
    }
    &.disabled {
        cursor: default;
        text-decoration: none;
        color: var(--neutral-60p);
    }
`;

export const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 44px;
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const FlexRowAligned = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const FlexRowSpaced = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const FlexRowCentered = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const FlexRowWrapped = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexColumnCentered = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ErrorMessage = styled.div`
    background: #f2dede;
    border: 1px solid #ebcccc;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    & > .message {
        font-family: InterUI-Regular;
        font-size: 16px;
        color: #ab352d;
        letter-spacing: 0;
        text-align: left;
        line-height: 25px;
    }
`;

export const StyledLink = styled(Link)`
    font-family: InterUI-Medium;
    font-size: 16px;
    color: #0061a8;
    text-decoration: none;
`;

export const BackButton = styled.img`
    cursor: pointer;
    width: 20px;
    height: 18px;
`;

export const ChatBackButton = styled(BackButton)`
    display: none;
    @media(max-width: 768px){
        position: fixed;
        top: 20px; 
        right: 20px;
        display: flex;
    }
`;

export const BurgerButton = styled.img`
    padding: ${(props) => (props.clicked ? '0px' : '4px')};
    width: ${(props) => (props.clicked ? '32px' : '24px')};
    height: ${(props) => (props.clicked ? '30px' : '14px')};
    margin-left: 20px;
`;

export const ProgressBar = styled.div`
    width: 230px;
    display: flex;
    flex-direction: row;
    background-color: rgb(242, 146, 67, 0.1);
    border-radius: 50px;
    height: 5px;
    align-self: center;
    margin: 20px 0px;
`;

export const ProgressFill = styled.div`
    width: ${(props) => (props.fill ? `${props.fill}` : '0px')};
    background: #ec5c57;
    border-radius: 50px;
`;

export const PrimaryButton = styled.button`
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid var(--red);
    box-sizing: border-box;
    /* button-default-shadow */
    box-shadow: 0px 2px 0px rgba(148, 148, 148, 0.25);
    border-radius: 32px;
    height: 100%;
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #61423e;

    &:hover {
        cursor: pointer;
        color: #000000;
        background: #fff4f3;
    }

    &:focus {
        cursor: pointer;
        color: #ffffff;
        background: var(--red);
        border: 1px solid var(--red);
    }

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

export const RoundProfile = styled.img`
    width: ${(props) => (props.width ? props.width : '84px')};
    height: ${(props) => (props.height ? props.height : '84px')};
    border-radius: 100%;
    margin: 4px 8px;

    @media (max-width: 768px) {
        width: ${(props) => (props.width ? props.width : '45px')};
        height: ${(props) => (props.height ? props.height : '45px')};
    }
`;
