import styled from 'styled-components/macro';

export const PageTitle = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 43px;
    line-height: 70px;

    @media (max-width: 768px) {
        font-size: 24px;
        line-height: 24px;
    }
`;

export const TeamLayout = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const ProTabContainer = styled.div`
    flex: 3;
    padding: 20px 60px;
    overflow: auto;

    @media (max-width: 768px) {
        flex: 1;
        padding: 20px;
    }
`;

export const Seperator = styled.div`
    width: 100%;
    opacity: 0.1;
    border: 1px solid #000000;
    margin: 50px 0;
`;

export const ImageNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ImageContainer = styled.div`
    width: 100%;
    max-width: 345px;
    max-height: 345px;
    min-width: 135px;
    min-height: 135px;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
`;

export const BannerContainer = styled.div`
    width: 100%;
    height: 17%;
    min-height: 25px;
    max-height: 54px;
    position: absolute;
    bottom: 0;
    background-color: var(--red);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BannerText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    margin-left: 5px;
`;

export const NameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 5% 0;
`;

export const NameText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 50px;
    color: var(--black);

    @media (max-width: 768px) {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        width: 60%;
        word-break: break-word;
    }
`;

export const TypeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 768px) {
        margin: 6px 0;
    }
`;

export const TypeText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: var(--black);
    margin: 30px 0;

    @media (max-width: 768px) {
        font-family: Poppins;
        font-size: 16px;
        line-height: 21px;
        width: 100%;
    }
`;

export const ShareText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--black);
    margin-left: 10px;
`;

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;

    @media (max-width: 768px) {
        justify-content: space-between;
        margin-top: 40px;
    }
`;

export const OptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
    }
`;

export const OptionText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    margin-left: 10px;

    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 21px;
    }
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const DetailsText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    margin: 12px 0;

    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 21px;
    }
`;

export const DetailsSpanText = styled.span`
    font-weight: normal;
`;

export const PicturesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    margin: 50px auto;
    border-radius: 15px;
    overflow: hidden;
    position: relative;

    @media (max-width: 800px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const Picture = styled.img`
    width: 100% !important;
    height: auto !important;
    object-position: center;
`;

export const ButtonContainer = styled.div`
    width: 15%;
    height: 15%;
    position: absolute;
    bottom: 15px;
    right: 15px;
    border-radius: 4px;
    z-index: 100;
`;

export const SeeAllButton = styled.button`
    background: #fff;
    box-sizing: border-box;
    border: 0;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--black);

    &:hover {
        cursor: pointer;
    }

    &:focus {
        cursor: pointer;
    }
`;

export const TitleText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: var(--black);
    margin-bottom: 15px;

    @media (max-width: 768px) {
        font-weight: bold;
    }
`;

export const DescriptionText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    margin-bottom: 15px;

    @media (max-width: 768px) {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
    }
`;

export const FooterText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: var(--black);
    margin-left: 10px;

    @media (max-width: 768px) {
        font-weight: 500;
    }
`;

export const FooterRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
`;

export const PlusContainer = styled.div`
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--red);
    border: 1px solid var(--red);
    border-radius: 13px;
    box-sizing: border-box;
`;
