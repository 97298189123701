import React, { useRef, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import backIcon from '../../../../assets/icons/back.svg';
import { ReactComponent as SendButton } from '../../../../assets/icons/send.svg';

import { ChatBackButton, FlexRow } from '../../../../common/components/CommonStyledComponent';
import { formatDate } from '../../../../common/utils/date-utils';
import { ChatAvatar, ChatHeaderContainer, ChatLayout, ChatSeparator, ChatTime, ChatTitle, AvatarName, ChatInput, AvatarContainer, AvatarFlex } from '../MessagesStyles';
import env from '../../../../config';
import { loadMessages, onMessageAdd, loadPreviousMessages } from '../../../../store/reducers/chat/chat-reducer';
import ChatMessage from './ChatMessage';
import InfinityScroll from '../../../../common/components/InfinityScroll';

const Chat = ({ chat, participants, clearChat }) => {
    const inputRef = useRef();
    const dispatch = useDispatch();

    const { messages, loading, hasPrevPage } = useSelector(state => state.chat);
    const { user } = useSelector(state => state.auth);
    const [messageId, setMessageId] = useState(null);
    const [resetScrollId, setResetScrollId] = useState(new Date().getTime());
    const [loadedMoreHash, setLoadedMoreHash] = useState(null);

    let mappedParticipants = useMemo(() => {
        let mapped = {};
        for (let i = 0; i < participants.length; i++) {
            mapped[participants[i].account._id] = participants[i];
        }
        return mapped;
    }, [participants]);

    const sendMessage = async () => {
        try {
            let message = inputRef.current.value;
            if (message !== '') {
                chat.sendMessage(message);
                inputRef.current.value = '';
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    const loadMoreMessages = () => {
        dispatch(loadPreviousMessages(chat));
        setLoadedMoreHash(new Date().toString());
    }

    useEffect(() => {

        const onAddMessage = (message) => {
            dispatch(onMessageAdd(message.state));
            chat.updateLastReadMessageIndex(message.state.index);
            setMessageId(new Date().toString());
        };

        if (chat) {
            chat.removeListener('messageAdded', onAddMessage);
            dispatch(loadMessages(chat));
            chat.on('messageAdded', onAddMessage);
            setResetScrollId(new Date().getTime())
        }

        return () => {
            chat.removeListener('messageAdded', onAddMessage);
        }
    }, [chat]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    }

    return (
        <ChatLayout>
            <ChatHeaderContainer>
                {chat ?
                    <>
                        <ChatTitle>{chat.channelState.friendlyName}</ChatTitle>
                        <ChatTime>{formatDate(chat.channelState.dateUpdated, "Asia/Jerusalem")}</ChatTime>
                    </>
                    : null}
            </ChatHeaderContainer>
            <AvatarFlex>
                {
                    participants ? participants.map((participant, index) => {
                        if (participant.pro.avatar) {
                            return <AvatarContainer key={index}>
                                <ChatAvatar style={{ marginRight: '0px' }} src={env.apiGateway.PROS_BUCKET_URL + participant?.pro?.avatar} alt="/" />
                                <AvatarName>{participant.pro ? participant.pro.businessName : participant.account.email}</AvatarName>
                            </AvatarContainer>
                        } else {
                            return null;
                        }
                    }) : null
                }
            </AvatarFlex>
            <ChatSeparator />
            <InfinityScroll resetScrollId={resetScrollId} loadedMoreHash={loadedMoreHash} onMessage={messageId} maxHeight={'100%'} hasMore={hasPrevPage} loading={loading} loadMore={loadMoreMessages}>
                {messages.map((message, index) => {
                    return <ChatMessage
                        key={index}
                        avatar={mappedParticipants[message.author]?.pro?.avatar}
                        name={mappedParticipants[message.author]?.account?.fullName}
                        isMe={user.userId === message.author}
                        message={message.body}
                        isSucessManager={mappedParticipants[message.author]?.account?.role === "manager"} />
                })}
            </InfinityScroll>
            <ChatSeparator />
            <FlexRow style={{ width: '100%', alignItems: 'center' }}>
                <ChatInput onKeyDown={handleKeyDown} ref={inputRef} placeholder={"Type here..."} style={{ width: '100%' }} />
                <SendButton onClick={sendMessage} style={{ cursor: 'pointer' }}></SendButton>
            </FlexRow>
            <ChatBackButton src={backIcon} alt="Back" onClick={clearChat} />
        </ChatLayout>
    )
}

export default Chat;