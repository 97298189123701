import styled from 'styled-components';


export const SettingsLayout = styled.div`
    display: flex;
    flex-direction: column;
    padding: 120px 90px;

    @media(max-width: 768px){
        padding: 20px;
    }
`;


export const SettingsTitle = styled.div`
    font-weight: 600;
    font-size: 43px;
    color: #222222;
    margin-bottom: 20px;

    @media(max-width: 768px){
        font-size: 20px;
    }

`;

export const SettingsTextButton = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin: 20px 0px;
    color: #222222;
    cursor: pointer;

    &:hover {
       opacity: 0.7;
    }
`;