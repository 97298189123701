export const CommonRoutes = {
    logout: '/logout',
    login: '/login',
    dashboard: '/dashboard',
};

export const ErrorRoutes = {
    error403: '/403',
    error404: '/404',
};

const DashboardRoutes = {
    messages: '/dashboard/messages',
    profile: '/dashboard/profile',
    settings: '/dashboard/settings',
};

const Routes = {
    Common: CommonRoutes,
    Errors: ErrorRoutes,
    Dashboard: DashboardRoutes,
};

export default Routes;
