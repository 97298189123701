import authReducer from './auth/auth-reducer';
import proReducer from './profile/pro-reducer';
import conversationsReducer from './conversations/conversations-reducer';
import chatReducer from './chat/chat-reducer';

export const reducers = {
    auth: authReducer,
    pro: proReducer,
    conversations: conversationsReducer,
    chat: chatReducer,
};
