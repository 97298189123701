import React, { useEffect } from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';

import { ToastContainer } from '../common/components/toast/ToastComponents';
import { authCheckState } from '../store/reducers/auth/auth-reducer';
import Routes from '../components/routes';
import Dashboard from '../components/Dashboard/Dashboard';
import twilioClient from '../twilio-client';
import { connectTwilio } from '../store/reducers/conversations/conversations-reducer';
import Login from '../components/Login/Login';

const App = () => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.user) {
      dispatch(authCheckState());
    }
  }, [auth.authenticated]);

  useEffect(() => {
    if (auth.user) {
      twilioClient.initializeTwilio().then(() => {
        dispatch(connectTwilio());
      });
    }
  }, [auth.authenticated])

  let view = (
    <div>
      LOADING!!!
    </div>);

  if (auth.authenticated && !auth.loading && auth.user) {
    view = (
      <Switch>
        <Route path={Routes.Common.dashboard} component={Dashboard}></Route>
        <Redirect from="/" to={Routes.Dashboard.messages} />
      </Switch>
    );
  }
  else if (!auth.authenticated && !auth.loading) {
    view = (
      <Switch>
        <Route path={Routes.Common.login} component={Login} exact={true}></Route>
        <Redirect from="/" to={Routes.Common.login} />
      </Switch>
    );
  }

  return (
    <ToastProvider components={{ ToastContainer: ToastContainer }}
      placement="bottom-center">
      {view}
    </ToastProvider>
  );
}

export default App;
