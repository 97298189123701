import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProTab from './components/ProTab';
import { TeamContainer, TeamLayout } from './styles/ProfileStyles';

import { cleanPro, loadMember } from '../../../store/reducers/profile/pro-reducer';
import PicturesPopup from './components/PicturesPopup';
import ProTabHeader from './components/ProTabHeader';
import Loader from '../../../common/components/Loader';

const Profile = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { pro, loading } = useSelector((state) => state.pro);
    const [showPicturesPopup, setShowPicturesPopup] = useState(false);
    // TODO add loader

    useEffect(() => {
        dispatch(loadMember(user.pid));

        return () => {
            dispatch(cleanPro());
        };
    }, []);

    const onPicClick = () => {
        setShowPicturesPopup(true);
    };

    const onPictureBackClick = () => {
        setShowPicturesPopup(false);
    };

    return (
        <TeamLayout>
            {loading ? <Loader /> : null}
            <TeamContainer>{pro && <ProTabHeader pro={pro} />}</TeamContainer>
            {pro && <ProTab pro={pro} onPicClick={onPicClick} />}
            {showPicturesPopup ? <PicturesPopup name={pro ? pro.fullName : ''} pictures={pro ? pro.images : []} onBackClick={onPictureBackClick} /> : null}
        </TeamLayout>
    );
};

export default Profile;
