import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Routes from '../routes';
import Messages from './Messages/Messages';
import Profile from './Profile/Profile';

import Settings from './Settings/Settings';
import { getTwilioToken } from '../../store/reducers/conversations/conversations-reducer';
import DashboardLayout from '../../hoc/DashboardLayout';

const Dashboard = () => {
    const { user, loading } = useSelector((state) => state.auth);

    useEffect(async () => {
        let twilioTok = await getTwilioToken();
        localStorage.setItem('pro_twilio_token', twilioTok);
    }, []);

    // TODO - loader should be absolute and hide background
    let view = <div>Loading</div>;

    if (!loading && user) {
        view = (
            <DashboardLayout>
                <Switch>
                    <Route path={Routes.Dashboard.profile} component={Profile} exact={true}></Route>
                    <Route path={Routes.Dashboard.messages} component={Messages} exact={true}></Route>
                    <Route path={Routes.Dashboard.settings} component={Settings} exact={true}></Route>
                    <Redirect to={Routes.Dashboard.profile} />
                </Switch>
            </DashboardLayout>
        );
    }

    return <>{view}</>;
};

export default Dashboard;
