import { string, object, array, number } from 'yup';

export const proSchema = object().shape({
    _id: string(),
    fullName: string(), //?
    phone: string().nullable(true),
    occupation: string(),
    occupationTitle: string(),
    priceRange: number(),
    experience: number(),
    avatar: string(),
    businessName: string().nullable(true),
    bio: string().nullable(true), //?
    facebook: string().nullable(true),
    tiktok: string().nullable(true),
    instagram: string().nullable(true),
    twitter: string().nullable(true),
    office: string().nullable(true),
    website: string().nullable(true),
    displayEmail: string(),
    address: string(),
    zip: string().nullable(true),
    language: string().nullable(true),
    businessId: string().nullable(true),
    currency: string(),
    images: array().of(string()).default([]),
    videos: array().of(string()).default([]),
    quota: array().of(string()).default([]),
    skechtes: array().of(string()).default([]),
    documents: array().of(string()).default([]),
});
