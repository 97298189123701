import React, { useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import Routes from '../routes';
import Nav from './NavigationStyles';

import burgerIcon from '../../assets/icons/hamburger.svg';
import xIcon from '../../assets/icons/x.svg';

import { ReactComponent as Logo } from '../../assets/icons/orange-fireworks.svg';
import { ReactComponent as MessagesIcon } from '../../assets/icons/messages-icon.svg';
import { ReactComponent as TeamIcon } from '../../assets/icons/team-icon.svg';

import { BurgerButton, FlexRowCentered } from '../../common/components/CommonStyledComponent';

const DashboardNavigation = () => {
    const { pathname } = useLocation();
    const [isBurgerClicked, setIsBurgerClicked] = useState(false);

    useEffect(() => {
        if (isBurgerClicked) {
            window.document.body.style.overflow = 'hidden';
        } else {
            window.document.body.style.overflow = 'auto';
        }
    }, [isBurgerClicked]);

    let iconsSize = {
        width: '29',
        height: '25',
    };

    const isWindowForMobile = window.innerWidth <= 768;
    if (isWindowForMobile) {
        iconsSize = {
            width: '20',
            height: '18',
        };
    }

    const closeNavigation = () => {
        setIsBurgerClicked(false);
    };

    return (
        <Nav.Nav isOpen={isBurgerClicked}>
            <Nav.TopNav bg={!isBurgerClicked && isWindowForMobile && pathname === '/dashboard/fantasies' ? 'transparent' : 'white'}>
                {isWindowForMobile ? (
                    isBurgerClicked ? (
                        <BurgerButton
                            clicked={isBurgerClicked}
                            onClick={() => {
                                setIsBurgerClicked(false);
                            }}
                            src={xIcon}
                        />
                    ) : (
                        <BurgerButton
                            clicked={isBurgerClicked}
                            onClick={() => {
                                setIsBurgerClicked(true);
                            }}
                            src={burgerIcon}
                        />
                    )
                ) : null}
                <Nav.NavLogo>
                    <Logo width={'100%'} height={'100%'} fill='#EC5C57' />
                </Nav.NavLogo>
            </Nav.TopNav>
            {
                <Nav.NavBarContainer show={!isWindowForMobile || (isBurgerClicked && isWindowForMobile)} isClicked={isBurgerClicked}>
                    <Nav.NavBar>
                        <Nav.NavItem onClick={closeNavigation}>
                            <Nav.NavStyledNavLink exact={false} to={Routes.Dashboard.profile}>
                                <FlexRowCentered>
                                    <TeamIcon width={iconsSize.width} height={iconsSize.height} fill={pathname === Routes.Dashboard.profile ? '#EC5C57' : '#c6c6c6'} style={{ marginRight: '20px' }} />
                                    {'Profile'}
                                </FlexRowCentered>
                            </Nav.NavStyledNavLink>
                        </Nav.NavItem>
                        <Nav.NavItem onClick={closeNavigation}>
                            <Nav.NavStyledNavLink exact={false} to={Routes.Dashboard.messages}>
                                <FlexRowCentered>
                                    <MessagesIcon
                                        width={iconsSize.width}
                                        height={iconsSize.height}
                                        fill={pathname === Routes.Dashboard.messages ? '#EC5C57' : '#c6c6c6'}
                                        style={{ marginRight: '20px' }}
                                    />
                                    {'Messages'}
                                </FlexRowCentered>
                            </Nav.NavStyledNavLink>
                        </Nav.NavItem>
                    </Nav.NavBar>
                    <Nav.NavControlsContainer>
                        <Nav.NavControlsListItem onClick={closeNavigation} selected={pathname === Routes.Dashboard.settings} exact={false} to={Routes.Dashboard.settings}>
                            {'Settings'}
                        </Nav.NavControlsListItem>
                    </Nav.NavControlsContainer>
                </Nav.NavBarContainer>
            }
        </Nav.Nav>
    );
};

export default withRouter(DashboardNavigation);
