import styled from 'styled-components';

export const TeamLayout = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 60px;
    }
`;

export const TeamContainer = styled.div`
    flex: 2;
    box-sizing: border-box;
    padding: 60px 0 20px 20px;
    overflow: hidden;
    min-width: 400px;

    @media (max-width: 768px) {
        padding: 0px 20px 20px 20px;
    }
`;

export const NameText = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: ${(props) => (props.clicked ? '#106CC1' : 'var(--black)')};
    margin-right: 8px;
    margin-left: 20px;

    @media (max-width: 768px) {
        margin-left: 15px;
        word-break: break-word;
    }
`;
