const ServerRoutes = {
    userLogin: '/api/v1/auth/token',
    userLogout: '/api/v1/auth/logout',
    sendSms: '/api/v1/auth/sms',

    getTwilioToken: '/api/v1/chat/token',
    chatParticipants: '/api/v1/chat/participants',

    getMember: 'api/v1/teams/member/:id',
};

export default ServerRoutes;
