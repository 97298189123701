import React from 'react';
import { ImageNameWrapper, ImageContainer, NameWrapper, NameText, TypeWrapper, TypeText, ShareText } from '../styles/ProTabStyles';
import { FlexRowAligned } from '../../../../common/components/CommonStyledComponent';
import env from '../../../../config';

import { ReactComponent as ShareIcon } from '../../../../assets/icons/share.svg';

const ProTabHeader = ({ pro }) => {
    return (
        <ImageNameWrapper>
            <ImageContainer>
                <img src={env.apiGateway.PROS_BUCKET_URL + pro.avatar} alt='' width={'100%'} height={'100%'} />
            </ImageContainer>
            <NameWrapper>
                <NameText>{pro.fullName}</NameText>
                <TypeWrapper>
                    <TypeText>
                        {pro.occupationTitle + ' '} <span style={{ fontWeight: 'normal' }}>{' ' + pro.businessName}</span>
                    </TypeText>
                    <FlexRowAligned style={{ cursor: 'pointer' }}>
                        <ShareIcon width={'14'} height={'16'} fill={'#EC5C57'} />
                        <ShareText>Share</ShareText>
                    </FlexRowAligned>
                </TypeWrapper>
            </NameWrapper>
        </ImageNameWrapper>
    );
};

export default ProTabHeader;
