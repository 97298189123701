import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';

const PhoneInput = (props) => {
    return (
        <ReactPhoneInput
            inputStyle={{
                borderRadius: 0,
                width: '100%',
                height: '44px',
                borderTop: 0,
                borderRight: 0,
                borderLeft: 0,
                // border: props.disabled ? '0 0 1px 0 solid var(--neutral-20p)' : '0 0 1px 0 solid var(--neutral-30p)',
                borderColor: (props) => (props.disabled ? 'var(--neutral-20p)' : 'var(--neutral-30p'),
                fontFamily: 'InterUI-Regular',
                fontSize: '16px',
                lineHight: '16px',
                color: 'var(--neutral-80p)',
            }}
            buttonStyle={{
                background: 'transparent',
                border: 0,
            }}
            country='il'
            {...props}
        />
    );
};

PhoneInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

PhoneInput.defaultProps = {
    onChange: () => {},
};

export default PhoneInput;
