import React from 'react';
import { DetailsText, DetailsContainer, DetailsSpanText } from '../styles/ProTabStyles';
import { FlexColumn } from '../../../../common/components/CommonStyledComponent';
import { currencyChar } from '../../../../common/utils/utils';

const getRange = (range) => {
    switch (range) {
        case 1:
            return 'Low';
        case 2:
            return 'Average';
        case 3:
            return 'High';

        default:
    }
};

const ProTabDetails = ({ pro }) => {
    return (
        <DetailsContainer>
            <FlexColumn>
                <DetailsText>
                    {currencyChar[pro.currency] + ' '}
                    <DetailsSpanText>{getRange(pro.priceRange)}</DetailsSpanText>
                </DetailsText>
                <DetailsText>
                    {'Area '}
                    <DetailsSpanText>{pro.activityArea}</DetailsSpanText>
                </DetailsText>
            </FlexColumn>
            <FlexColumn>
                <DetailsText>
                    {'Typical Job cost '}
                    <DetailsSpanText>{'5,000 $'}</DetailsSpanText>
                </DetailsText>
                <DetailsText>
                    {'Languages '}
                    <DetailsSpanText>{pro.language}</DetailsSpanText>
                </DetailsText>
            </FlexColumn>
            <FlexColumn>
                <DetailsText>
                    {'+' + pro.experience + 'y. '}
                    <DetailsSpanText>Experience</DetailsSpanText>
                </DetailsText>
            </FlexColumn>
        </DetailsContainer>
    );
};

export default ProTabDetails;
