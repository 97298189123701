import axiosInstance from '../../axios';
import ServerRoutes from './routes/index';
import { errorSchema } from './schemas/common-schemas';
import { proSchema } from './schemas/teams-schemas';
import { validate } from './schemas/validator';

const fetchMember = async (proId) => {
    try {
        let url = ServerRoutes.getMember.replace(':id', proId);
        const { status, data, error } = await axiosInstance.get(url);
        const { result, e, s } = await validate(proSchema, data, false);
        return { data: result, status: s ? s : status, error: e ? e : error };
    } catch (error) {
        console.error(JSON.stringify(error));
        const e = errorSchema.cast({ ...error });
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (e.response.status) {
            case 404:
                message = 'Pro were not generated';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: message,
            status: e.response.status,
        };
    }
};

const teamsApi = {
    fetchMember: fetchMember,
};

export default teamsApi;
