import styled from 'styled-components';

export const LoginLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins';
    height: 100%;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    box-sizing: border-box;

    /* @media (max-width: 768px) {
    } */
`;

export const LogoContainer = styled.div`
    height: 130px;
    width: 130px;
    padding: 30px 100px;

    @media only screen and (max-width: 768px) {
        height: 40px;
        width: 40px;
        padding: 0px 30px 0px 0px;
        margin: auto;
    }
`;

export const LoginHeader = styled.div`
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 48px;
    padding: 20px 0px 60px 0px;
    color: var(--black);

    @media (max-width: 768px) {
        font-size: 32px;
        padding: 20px 0px 40px 0px;
    }
`;

export const FormBox = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-family: 'Poppins';
    height: 100%;
    border-radius: 50px 50px 0px 0px;
    overflow: scroll;
    width: 80%;
    align-self: center;
    color: var(--black);
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    border-bottom: none;

    @media (max-width: 768px) {
        width: 80%;
    }
`;

export const LoginButtonsContainer = styled.div`
    position: relative;
    background: ${(props) => `url(${props.bg})`};
    background-size: cover;
    height: 50px;
    width: 275px;
    margin: 10px 0;
`;

export const StyledInput = styled.input`
    color: var(--black);
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 18px;
    padding: 15px 0;
    /* margin: 8px auto; */
    width: 275px;
    border: 0;
    background-color: #fff;
    border-bottom: 2px solid var(--neutral-50p);

    &:disabled {
        border: 1px solid var(--neutral-50p);
        color: var(--neutral-50p);
    }

    &.readOnly:disabled {
        color: var(--neutral-80p);
    }

    &:focus {
        outline: none;
        color: var(--neutral-90p);
    }

    &::-webkit-input-placeholder {
        /* Edge */
        font-family: 'Poppins';
        font-size: 18px;
        line-height: 18px;
        color: var(--neutral-40p);
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-family: 'Poppins';
        font-size: 18px;
        line-height: 18px;
        color: var(--neutral-40p);
    }

    &::placeholder {
        font-family: 'Poppins';
        font-size: 18px;
        line-height: 18px;
        color: var(--neutral-40p);
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 30px 0;
    align-self: center;
`;

export const ButtonContainer = styled.div`
    width: 170px;
    height: 56px;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '0p')};
    align-self: center;
`;

export const Line = styled.div`
    width: 30%;
    border: 1px solid #a89e9a;
`;

export const BoldText = styled.div`
    color: #a89e9a;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
`;

export const RegularText = styled.div`
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    font-weight: 500;
    margin: 30px 0;
`;
