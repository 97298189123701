import React, { useState } from 'react';
import { ProTabContainer, Seperator, PicturesContainer, Picture, ButtonContainer, SeeAllButton, DescriptionText, TitleText } from '../styles/ProTabStyles';
import env from '../../../../config';

import { ReactComponent as SeeAllIcon } from '../../../../assets/icons/see-all.svg';

import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import ProTabFooter from './ProTabFooter';
import ProTabSocial from './ProTabSocial';
import ProTabFiles from './ProTabFiles';
import ProTabDetails from './ProTabDetails';

const ProTab = ({ pro, onPicClick }) => {
    const { width } = useWindowDimensions();
    const [maxLength, setMaxLength] = useState(width > 768 ? 10 : 9);

    return (
        <ProTabContainer>
            <ProTabFiles pro={pro} />
            <Seperator></Seperator>
            <ProTabDetails pro={pro} />
            <PicturesContainer>
                {pro.images && //TODO handle videos (pro.videos)
                    pro.images.map((image, index) => {
                        if (index < maxLength) {
                            return (
                                <Picture
                                    key={index}
                                    alt=''
                                    src={env.apiGateway.PROS_BUCKET_URL + image}
                                    onClick={() => {
                                        onPicClick();
                                    }}></Picture>
                            );
                        } else {
                            return null;
                        }
                    })}
                {pro.images.length > maxLength ? (
                    <ButtonContainer>
                        <SeeAllButton
                            onClick={() => {
                                setMaxLength(20);
                            }}>
                            <SeeAllIcon width={'12'} height={'12'} fill={'#EC5C57'} style={{ marginRight: '5px' }} />
                            See All
                        </SeeAllButton>
                    </ButtonContainer>
                ) : null}
            </PicturesContainer>
            <TitleText>{pro.occupationTitle}</TitleText>
            <DescriptionText>{pro.bio}</DescriptionText>
            <ProTabSocial pro={pro} />
            <ProTabFooter pro={pro} />
        </ProTabContainer>
    );
};

export default ProTab;
