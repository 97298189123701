import React from 'react';
import { DetailsContainer, FooterText, FooterRowWrapper } from '../styles/ProTabStyles';
import { FlexColumn } from '../../../../common/components/CommonStyledComponent';

import { ReactComponent as MailIcon } from '../../../../assets/icons/mail.svg';
import { ReactComponent as GlobusIcon } from '../../../../assets/icons/globus.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/icons/phone.svg';
import { ReactComponent as LocationIcon } from '../../../../assets/icons/location.svg';
import { ReactComponent as NoteIcon } from '../../../../assets/icons/note-icon.svg';

const ProTabFooter = ({ pro }) => {
    return (
        <DetailsContainer>
            <FlexColumn>
                {pro.displayEmail ? (
                    <FooterRowWrapper>
                        <MailIcon width={'13'} height={'10'} fill={'#222222'} />
                        <FooterText>{pro.displayEmail}</FooterText>
                    </FooterRowWrapper>
                ) : null}
                {pro.website ? (
                    <FooterRowWrapper>
                        <GlobusIcon width={'13'} height={'13'} fill={'#222222'} />
                        <FooterText>{pro.website}</FooterText>
                    </FooterRowWrapper>
                ) : null}
                {pro.phone ? (
                    <FooterRowWrapper>
                        <PhoneIcon width={'10'} height={'10'} fill={'#222222'} />
                        <FooterText>{pro.phone}</FooterText>
                    </FooterRowWrapper>
                ) : null}
            </FlexColumn>
            <FlexColumn style={{ minWidth: '40%' }}>
                {pro.address ? (
                    <FooterRowWrapper>
                        <LocationIcon width={'9'} height={'11'} fill={'#222222'} />
                        <FooterText>{pro.address}</FooterText>
                    </FooterRowWrapper>
                ) : null}
                {pro.businessId ? (
                    <FooterRowWrapper>
                        <NoteIcon width={'9'} height={'11'} fill={'#222222'} />
                        <FooterText>{pro.businessId}</FooterText>
                    </FooterRowWrapper>
                ) : null}
            </FlexColumn>
        </DetailsContainer>
    );
};

export default ProTabFooter;
