import React from 'react';
import '../../styles/loader.css';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow: hidden;
`;

const Loader = () => {
    let containers = [];
    for (let i = 0; i < 200; i++) {
        containers.push(
            <div className='container' key={i}>
                <div className='star'></div>
            </div>
        );
    }

    return (
        <Container>
            <div className='loader'>
                <div className='center'></div>
                {containers}
            </div>
            <div className='loaderborder'></div>
        </Container>
    );
};

export default Loader;
