import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../common/components/Loader';
import { cleanMessages } from '../../../store/reducers/chat/chat-reducer';
import twilioClient from '../../../twilio-client';

import { loadConversations, cleanChats, loadParticipants, addUnreadMessage, setCurrentChatSid } from '../../../store/reducers/conversations/conversations-reducer';
import Chat from './components/Chat';

import ChatConversations from './components/ChatConversations';
import { MessagesGrid } from './MessagesStyles';

const Messages = () => {
    const dispatch = useDispatch();
    const { twilioConnected, conversations, participants, version, currentChatSid } = useSelector((state) => state.conversations);
    const [currentParticipants, setCurrentParticipants] = useState(null);
    const [clearSelectedTrigger, setClearSelectedTrigger] = useState(null);

    const onNewMessageRecieved = useCallback((coversationData) => {
        let convSid = coversationData.conversation.sid;
        dispatch(addUnreadMessage(convSid));
    }, [currentChatSid]);

    useEffect(() => {
        if (twilioConnected) {
            dispatch(loadConversations());
            dispatch(loadParticipants());
            twilioClient.client.on('messageAdded', onNewMessageRecieved)
        }
        return () => {
            dispatch(cleanChats());
            dispatch(cleanMessages());
            twilioClient.client.removeListener('messageAdded', onNewMessageRecieved);
        };
    }, [twilioConnected]);

    const clearCurrentChat = () => {
        dispatch(setCurrentChatSid(null));
        setCurrentParticipants(null);
        setClearSelectedTrigger(new Date().toString());
    };

    return (
        <MessagesGrid>
            {!twilioConnected ? <Loader /> : null}
            <ChatConversations
                clearSelectedTrigger={clearSelectedTrigger}
                onSelectChat={(chatSid) => dispatch(setCurrentChatSid(chatSid))}
                onSelectParticipants={setCurrentParticipants}
                chats={conversations}
                participants={participants}
                version={version}
            />
            {currentParticipants && currentChatSid ? <Chat clearChat={clearCurrentChat} chat={conversations[currentChatSid]} participants={currentParticipants.participants} /> : null}
        </MessagesGrid>
    );
};

export default Messages;