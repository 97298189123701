import { createSlice } from '@reduxjs/toolkit';
import * as jwt from 'jsonwebtoken';
import { updateToken } from '../../../axios';
import authApi from '../../api/auth-api';
import { cleanChats, disconnectTwilio } from '../conversations/conversations-reducer';
import twilioClient from '../../../twilio-client';
import { getTokenRefreshTime } from '../../../common/utils/utils';

const initialState = {
    user: null,
    authenticated: false,
    error: null,
    loading: false,
};

const _loadStart = (state, action) => {
    state.loading = true;
    state.error = null;
};

const _loadFailed = (state, action) => {
    const error = action.payload;
    state.loading = false;
    state.error = error;
};

const _authSuccess = (state, action) => {
    const { user } = action.payload;
    state.loading = false;
    state.user = user;
    state.authenticated = true;
};

const _authLogout = (state) => {
    localStorage.clear();
    return initialState;
};

const _authUserInfoLoaded = (state, action) => {
    const { user } = action.payload;
    state.user = user;
    state.loading = false;
    state.authenticated = true;
    state.error = null;
};

const _authFailed = (state, action) => {
    const { error } = action.payload;
    localStorage.clear();
    state.error = error;
    state.loading = false;
    state.user = null;
    state.authenticated = false;
};

const _sendSmsSuccess = (state, action) => {
    state.loading = false;
    state.error = null;
};

const _cleanNewUser = (state, action) => {
    state.user.isNewUser = false;
};

const auth = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        loadStart: _loadStart,
        loadFailed: _loadFailed,
        authSuccess: _authSuccess,
        authLogout: _authLogout,
        authUserInfoLoaded: _authUserInfoLoaded,
        authFailed: _authFailed,
        sendSmsSuccess: _sendSmsSuccess,
        cleanNewUser: _cleanNewUser,
    },
});

const { actions, reducer } = auth;

export const { loadStart, loadFailed, authSuccess, authLogout, authUserInfoLoaded, authFailed, sendSmsSuccess, cleanNewUser } = actions;

export default reducer;

export const authSelector = (state) => state.auth;

export const authCheckState = () => {
    return async (dispatch) => {
        dispatch(loadStart());
        const token = localStorage.getItem('pro_access_token');
        const id_token = localStorage.getItem('pro_id_token');
        const refresh_token = localStorage.getItem('pro_refresh_token');
        if (!token) {
            return dispatch(authLogout());
        }

        const decoded = jwt.decode(token);
        const expiration = decoded.exp;
        const refreshTime = getTokenRefreshTime(token);
        if (!expiration || expiration < new Date().getTime() / 1000) {
            let loginData = {
                grant_type: 'refresh_token',
                refresh_token: refresh_token,
            };
            const { status, data } = await authApi.login(loginData);
            if (status === 200 && data.access_token) {
                localStorage.setItem('pro_access_token', data.access_token);
                localStorage.setItem('pro_id_token', data.id_token);
                localStorage.setItem('pro_refresh_token', data.refresh_token);
                localStorage.setItem('pro_expires_in', data.expires_in);
                localStorage.setItem('pro_token_type', data.token_type);
                updateToken();
                const userInfo = jwt.decode(data.id_token);
                if (userInfo) {
                    return dispatch(authUserInfoLoaded({ user: { email: userInfo.email, fullName: userInfo.fullName, userId: decoded.id, pid: userInfo.pid } }));
                } else {
                    return dispatch(authFailed({ error: 'Invalid token provided' }));
                }
            } else {
                return dispatch(authUserLogout());
            }
        } else {
            updateToken();
            scheduleTokenRefresh(dispatch, refreshTime);
            const userInfo = jwt.decode(id_token);
            if (userInfo) {
                return dispatch(authUserInfoLoaded({ user: { email: userInfo.email, fullName: userInfo.fullName, userId: userInfo.id, pid: userInfo.pid } }));
            } else {
                return dispatch(authFailed('Invalid token provided'));
            }
        }
    };
};

export const authUserLogin = (loginData) => {
    return async (dispatch) => {
        dispatch(loadStart());
        const { status, data, error } = await authApi.login(loginData);
        if (status === 200 && data.access_token) {
            localStorage.setItem('pro_access_token', data.access_token);
            localStorage.setItem('pro_id_token', data.id_token);
            localStorage.setItem('pro_refresh_token', data.refresh_token);
            localStorage.setItem('pro_expires_in', data.expires_in);
            localStorage.setItem('pro_token_type', data.token_type);
            updateToken();
            scheduleTokenRefresh(dispatch, getTokenRefreshTime(data.access_token));
            const userInfo = jwt.decode(data.id_token);
            if (userInfo) {
                return dispatch(authSuccess({ user: { email: userInfo.email, fullName: userInfo.fullName, userId: userInfo.id, pid: userInfo.pid } }));
            } else {
                return dispatch(authFailed({ error: 'Invalid token provided' }));
            }
        } else {
            return dispatch(authFailed({ error }));
        }
    };
};

export const authUserLogout = () => {
    return async (dispatch) => {
        let refreshToken = localStorage.getItem('pro_refresh_token');
        if (!refreshToken) {
            return dispatch(authLogout());
        }
        dispatch(loadStart());
        const { status, data, error } = await authApi.logout(refreshToken);
        if (status === 200 && data.ok === 1) {
            dispatch(cleanChats());
            dispatch(disconnectTwilio());
            twilioClient.disconnect();
            return dispatch(authLogout());
        } else {
            return dispatch(authFailed({ error: error }));
        }
    };
};

export const sendSms = (phoneData) => {
    return async (dispatch) => {
        const { status, data, error } = await authApi.sendSms(phoneData);
        if (status === 200) {
            return dispatch(sendSmsSuccess(data));
        } else {
            return dispatch(loadFailed({ error: error }));
        }
    };
};

let timer = null;

const scheduleTokenRefresh = (dispatch, time) => {
    if (timer) {
        clearTimeout(timer);
    }
    timer = setTimeout(async () => {
        const error = await loadTokens(dispatch);
        if (error) {
            console.error(error);
            dispatch(authUserLogout())
        }
    }, time)
};

const loadTokens = async (dispatch) => {
    let refresh_token = localStorage.getItem('pro_refresh_token');
    if (!refresh_token) {
        return 'refresh token not found';
    }
    let loginData = {
        grant_type: 'refresh_token',
        refresh_token: refresh_token
    }
    const { status, data, error } = await authApi.login(loginData);
    if (status === 200 && data.access_token) {
        localStorage.setItem('pro_access_token', data.access_token);
        localStorage.setItem('pro_id_token', data.id_token);
        localStorage.setItem('pro_refresh_token', data.refresh_token);
        localStorage.setItem('pro_expires_in', data.expires_in);
        localStorage.setItem('pro_token_type', data.token_type);
        updateToken();
        scheduleTokenRefresh(dispatch, getTokenRefreshTime(data.access_token));
    }
    return error;
}
