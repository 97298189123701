import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SettingsLayout, SettingsTextButton, SettingsTitle } from './SettingsStyles';
import { authUserLogout } from '../../../store/reducers/auth/auth-reducer';

const Settings = () => {
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(authUserLogout());
    }

    return <SettingsLayout>
        <SettingsTitle>Settings</SettingsTitle>
        <SettingsTextButton onClick={logout}>Logout {user.fullName ? <b>({user.fullName})</b> : user.email ? <b>({user.email})</b> : null}</SettingsTextButton>
    </SettingsLayout>
}

export default Settings;
